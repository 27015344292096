import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import parse from "html-react-parser"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Banner from "../components/Banner"
import PlayerCard from "../components/PlayerCard"

const MinuteEmploiPage = function ({
  data: { minuteEmploiJson, ensembleAuMicroPodcastsJson, settingsJson },
  location,
}) {
  return (
    <Layout>
      <Seo
        title={minuteEmploiJson.title}
        description={minuteEmploiJson.description}
        pathname={location.pathname}
      />
      <Banner
        background={minuteEmploiJson.banner.background}
        content={minuteEmploiJson.banner.content}
      />
      <div className="container">
        <section>
          {parse(minuteEmploiJson.title1)}
          {parse(minuteEmploiJson.content1)}
          {parse(minuteEmploiJson.title2)}
          {parse(minuteEmploiJson.content2)}
          <ul className="grid md:grid-cols-3 lg:grid-cols-4 md:gap-5">
            {ensembleAuMicroPodcastsJson.podcasts
              .filter((item) => item.category === `emploi`)
              .sort((a, b) => b.active - a.active)
              .map(function (item) {
                return (
                  <PlayerCard
                    key={uuidv4()}
                    item={item}
                    settings={settingsJson}
                  />
                )
              })}
          </ul>
        </section>
      </div>
    </Layout>
  )
}

MinuteEmploiPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default MinuteEmploiPage

export const query = graphql`
  query MinuteEmploiPageQuery {
    minuteEmploiJson {
      title
      description
      banner {
        background {
          publicURL
        }
        content
      }
      title1
      content1
      title2
      content2
    }
    ensembleAuMicroPodcastsJson(
      podcasts: { elemMatch: { category: { eq: "emploi" } } }
    ) {
      podcasts {
        category
        num
        date
        title
        voices
        file
        active
      }
    }
    settingsJson {
      podcastsURL
    }
  }
`
